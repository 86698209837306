import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

export default function Status({ statusDetails }) {
  return (
    <div>
      <Col xl="12">
        <Row>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-bitcoin h2 text-warning mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <p className="text-muted mb-2">Active Breathers</p>
                    <h1 className="mb-0">{statusDetails.activeBreathers}</h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-ethereum h2 text-primary mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <p className="text-muted mb-2">Recharing breathers</p>
                    <h1 className="mb-0">
                      {statusDetails.rechargingBreathers}
                    </h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-litecoin h2 text-info mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <p className="text-muted mb-2">Stand by breathers</p>
                    <h1 className="mb-0">{statusDetails.standbyBreathers}</h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-litecoin h2 text-info mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <p className="text-muted mb-2">Total breathers</p>
                    <h1 className="mb-0">{statusDetails.totalBreathers}</h1>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  )
}
