import PropTypes from "prop-types"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import axios from "axios"
// import withRouter from "components/Common/withRouter";

// Formik Validation
// import * as Yup from "yup";
// import { useFormik } from "formik";

// action
// import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

const ForgetPasswordPage = props => {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }

  //meta title
  document.title = "Forget Password | Skote - React Admin & Dashboard Template"

  const [email, setemail] = React.useState(undefined)
  const [error, seterror] = React.useState("")
  const [success, setsucess] = React.useState("")

  const Emailvalid = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const ForgetPassword = async () => {
    if (email !== undefined && Emailvalid(email)) {
      try {
        let response = await axios.post(
          `${baseUrl}/api/v1/adminAuth/admin-forgotpassword`,

          {
            email: email,
          }
        )
        if (
          response.data !== undefined &&
          response.data.token !== undefined &&
          response.status === 200
        ) {
          localStorage.setItem("token", response.data.token)
        }
        console.log(response)
        setsucess(
          "Password reset link has been sent to your registered email account"
        )
      } catch (e) {
        if (e.response !== undefined && e.response.data !== undefined) {
          seterror(e.response.data.msg)
          seterror("")
        }
      }
    } else {
      seterror("Enter email to continue")
      setsucess("")
      return undefined
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-light p-4">
                        <h5 className="text-light">Welcome Back !</h5>
                        <p>Sign in to Continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}
                    {error != "" ? <Alert color="danger">{error}</Alert> : null}
                    {success != "" ? (
                      <Alert color="success">{success}</Alert>
                    ) : null}
                    {/* {success!="" ? <Alert color="Success">{success}</Alert> : null} */}

                    <Form
                      className="form-horizontal"
                      // onSubmit={(e) => {
                      //   e.preventDefault();
                      //   validation.handleSubmit();
                      //   return false;
                      // }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          onChange={e => {
                            setemail(e.target.value)
                          }}
                          // onChange={validation.handleChange}
                          // onBlur={validation.handleBlur}
                          // value={validation.values.email || ""}
                          // invalid={
                          //   validation.touched.email && validation.errors.email ? true : false
                          // }
                        />
                        {/* {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null} */}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="button"
                            onClick={ForgetPassword}
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link
                    to="/admin-login"
                    className="font-weight-medium text-primary"
                  >
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Yogya.
                  {/* Crafted with{" "} */}
                  {/* <i className="mdi mdi-heart text-danger" /> by Themesbrand */}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// ForgetPasswordPage.propTypes = {
//   history: PropTypes.object,
// };

export default ForgetPasswordPage
