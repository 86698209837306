import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  if (!localStorage.getItem("token")){
    if(!localStorage.getItem("logintype")){
      return (
        <Navigate to={{ pathname: "/admin-login", state: { from: props.location } }} />
      );
    } else {
      return (
        <Navigate to={{ pathname: "/"+localStorage.getItem("logintype"), state: { from: props.location } }} />
      );
    }
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
