import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"
import { useState, useEffect } from "react"
import axios from "axios"

const Spinearea = ({
  dataColors,
  plotDates,
  deviceId,
  activeBreather,
  value1,
  value2,
}) => {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }



  const [plottingData, setPlottingData] = useState({
    BR1_RH: [],
    BR1_TEMP: [],
    BR2_RH: [],
    BR2_TEMP: [],
    BR1_LIFE: [],
    BR2_LIFE: [],
    BR1_STATUS: [],
    BR2_STATUS: []
  })

  const [startAndEndValue, setStartAndEndValue] = useState({
    start: new Date().toISOString(),
    end: new Date().toISOString(),
  })



  const plotDetails = async () => {
    setStartAndEndValue({
      ...startAndEndValue,
      start: plotDates.start,
      end: plotDates.end,
    })

    const bodyParams = {
      deviceId: deviceId,
      startDate: plotDates.start,
      endDate: plotDates.end,
    }
    const response1 = await axios.post(
      `${baseUrl}/api/v1/dashboard/getGraphDataInRange`,
      bodyParams
    )

    // console.log('---------------------------');
    // console.log(response1.data);
    // console.log('---------------------------');



    if (response1.data && response1.data.BR1_RH) {
      setPlottingData({
        ...plottingData,
        BR1_RH: response1.data.BR1_RH,
        BR1_TEMP: response1.data.BR1_TEMP,
        BR2_RH: response1.data.BR2_RH,
        BR2_TEMP: response1.data.BR2_TEMP,
        BR1_LIFE: response1.data.BR1_LIFE,
        BR2_LIFE: response1.data.BR2_LIFE,
        BR1_STATUS: response1.data.BR1_STATUS,
        BR2_STATUS: response1.data.BR2_STATUS,
      })
    }

    // console.log('---------------------------');
    // console.log(plottingData);
    // console.log('---------------------------');


  }

  useEffect(() => {
    plotDetails()
  }, [deviceId])

  useEffect(() => {
    plotDetails()
  }, [plotDates])

  const spineareaChartColors = getChartColorsArray(dataColors)

  const labelData = []
  const br1Data = []
  const br2Data = []

  const br3Data = []
  const br4Data = []

  let system_rh = []
  let system_temp = []

  plottingData.BR1_RH.map((data, index) => {
    let date = new Date(data[1])
    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const formattedDate = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: 'numeric',
      minute: 'numeric',
    }).format(utcDate)
    console.log(plottingData.BR1_STATUS);
    if (plottingData.BR1_STATUS[index][0] == 'ACTIVE') {
      labelData.push(formattedDate)
      br2Data.push(data[0])
    }
  })
  plottingData.BR1_TEMP.map((data, index) => {
    if (plottingData.BR1_STATUS[index][0] == 'ACTIVE') {
      br1Data.push(data[0])
    }
  })

  plottingData.BR2_RH.map((data, index) => {
    let date = new Date(data[1])
    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    // const formattedDate = new Intl.DateTimeFormat("en-Us", {
    //   day: "2-digit",
    //   month: "2-digit",
    //   year: "2-digit",
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   timeZoneName: 'short',
    //   timeZone: 'Asia/Kolkata'
    // }).format(utcDate)
    const formattedDate = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: 'numeric',
      minute: 'numeric',
    }).format(utcDate)
    if (plottingData.BR2_STATUS[index][0] == 'ACTIVE') {
      labelData.push(formattedDate)
      br4Data.push(data[0])
    }
  })
  plottingData.BR2_TEMP.map((data, index) => {
    if (plottingData.BR2_STATUS[index][0] == 'ACTIVE') {
      br3Data.push(data[0])
    }
  })

  system_rh = [...br2Data, ...br4Data]
  system_temp = [...br1Data, ...br3Data]

  const finalSystemTemp = []
  const finalSystemRh = []

  for (let i = 0; i < labelData.length; i++) {
    if (system_rh[i] != 0) {
      finalSystemRh.push(system_rh[i])
    }
    if (system_temp[i] != 0) {
      finalSystemTemp.push(system_temp[i])
    }
  }

  // console.log("============================================================");
  // console.log(br1Data); // temp
  // console.log(br2Data); // rh
  // console.log(br3Data); // temp
  // console.log(br4Data); // rh
  // console.log(response1);
  // console.log(system_rh);
  // console.log(system_temp);
  // console.log(finalSystemTemp);
  // console.log(finalSystemRh);
  // console.log(labelData);
  // console.log(plotDates);
  // console.log("============================================================");






  const series = [
    {
      name: value1,
      type: 'area',
      data: finalSystemTemp,
    },
    {
      name: value2,
      type: 'area',
      data: finalSystemRh,
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "stepline",
      width: 3,
    },

    colors: spineareaChartColors,
    xaxis: {
      visible: false,
      type: "datetime",
      categories: labelData,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  }



  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
      plotDates={plotDates}
    />
  )
}

export default Spinearea
