import React from "react"

// import apexChart
import PieChart from "../AllCharts/apex/PieChart"
import Breathers from "pages/AllCharts/apex/PieChart1"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ApexChart = ({ plottingValues1, plottingValues2 }) => {
  //meta title
  document.title = "Yogya | Dashboard"

  const seriesValues1 = plottingValues1
  const seriesValues2 = plottingValues2
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Status" breadcrumbItem="Breathers Details" />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Breathers Status </CardTitle>
                  <PieChart
                    seriesValues={seriesValues1}
                    dataColors='["--bs-success","--bs-warning", "--bs-danger","--bs-info", "--bs-warning"]'
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Breathers Charging Status{" "}
                  </CardTitle>
                  <Breathers
                    seriesValues={seriesValues2}
                    dataColors='["--bs-success","--bs-warning", "--bs-danger","--bs-info", "--bs-warning"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ApexChart
