import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"
import { useState, useEffect } from "react"
import axios from "axios"

const Spinearea1 = ({
  dataColors,
  plotDates,
  deviceId,
  activeBreather,
  value1,
  value2,
  key
}) => {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }

  const [plottingData, setPlottingData] = useState({
    BR1_RH: [],
    BR1_TEMP: [],
    BR2_RH: [],
    BR2_TEMP: [],
    BR1_LIFE: [],
    BR2_LIFE: [],
  })

  const [startAndEndValue, setStartAndEndValue] = useState({
    start: new Date().toISOString(),
    end: new Date().toISOString(),
  })

  const plotDetails = async () => {




    setStartAndEndValue({
      ...startAndEndValue,
      start: plotDates.start,
      end: plotDates.end,
    })


    const bodyParams = {
      deviceId: deviceId,
      startDate: plotDates.start,
      endDate: plotDates.end,
    }

    const response1 = await axios.post(
      `${baseUrl}/api/v1/dashboard/getGraphDataInRange`,
      bodyParams
    )

    if (response1.data && response1.data.BR1_RH) {
      setPlottingData({
        ...plottingData,
        BR1_RH: response1.data.BR1_RH,
        BR1_TEMP: response1.data.BR1_TEMP,
        BR2_RH: response1.data.BR2_RH,
        BR2_TEMP: response1.data.BR2_TEMP,
        BR1_LIFE: response1.data.BR1_LIFE,
        BR2_LIFE: response1.data.BR2_LIFE,
      })
    }
  }

  useEffect(() => {
    plotDetails()
  }, [deviceId])

  useEffect(() => {
    plotDetails()
  }, [plotDates])

  const spineareaChartColors = getChartColorsArray(dataColors)

  const labelData = []
  const br1Data = []
  const br2Data = []

  plottingData.BR1_LIFE.map(data => {
    let date = new Date(data[1])
    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(utcDate)
    labelData.push(formattedDate)

    br1Data.push(data[0])
  })
  plottingData.BR2_LIFE.map(data => {
    br2Data.push(data[0])
  })


  const series = [
    {
      name: value1,
      data: br1Data,
    },
    {
      name: value2,
      data: br2Data,
    },
  ]

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: spineareaChartColors,
    xaxis: {
      visible: false,
      type: "datetime",
      categories: labelData,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  }


  return (
    <div >
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="350"
      />
    </div>
  )
}

export default Spinearea1
