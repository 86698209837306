import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import axios from "axios"

// Icons

import rechargeIcon from "../../assets/images/alerts/recharge.png"
import temperatureIcon from "../../assets/images/alerts/temperature.png"
import humidityIcon from "../../assets/images/alerts/humidity.png"
import fanIcon from "../../assets/images/alerts/fan.png"
import solenoidIcon from "../../assets/images/alerts/solenoid.png"

const iconsMapping = {
  recharge: rechargeIcon,
  temperature: temperatureIcon,
  humidity: humidityIcon,
  fan: fanIcon,
  solenoide: solenoidIcon,
}

//Simple bar
import SimpleBar from "simplebar-react"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Pages
import Alarm from "./Alarm"
import Alert from "./Alert"
import PieChart from "./ApexChart"
import Status from "./Status"

//i18n
import { withTranslation } from "react-i18next"
const Dashboard = props => {
  const navigate = useNavigate()
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }
  //meta title
  document.title = "Yogya | Dashboard"

  const [statusDetails, setStatusDetails] = useState({
    activeBreathers: 0,
    rechargingBreathers: 0,
    standbyBreathers: 0,
    errorBreathers: 0,
    totalBreathers: 0,
    alertMessages: [100],
    errorMessages: [100],
    lastUpdatedOn: new Date(),
  })

  const [alertMessagesData, setAlertMessagesData] = useState({
    rechargeAlerts: 0,
    temperatureAlerts: 0,
    humidityAlerts: 0,
    fanAlerts: 0,
    solenoideAlerts: 0,
  })

  const [errorMessagesData, setErrorMessagesData] = useState({
    rechargeErrors: 0,
    temperatureErrors: 0,
    humidityErrors: 0,
    fanErrors: 0,
    solenoideErrors: 0,
  })

  const [userRole, setUserRole] = useState("")

  useEffect(() => {
    const getTokenDetails = async () => {
      try {
        const token = {
          token: localStorage.getItem("token"),
        }

        const response = await axios.post(
          `${baseUrl}/api/v1/dashboard/getTokenDetails`,
          token
        )
        if (response.data.error) {
          localStorage.clear()
          navigate("/admin-login")
        } else {
          setUserRole(response.data.role)
        }
      } catch {}
    }
    getTokenDetails()
  }, [userRole == ""])

  useEffect(() => {
    const getStatusDetails = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/dashboard/getLatestData`
        )
        console.log(response)
        setStatusDetails({
          ...statusDetails,
          ...response.data,
        })
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }

    getStatusDetails()
    console.log(statusDetails)
  }, [])

  useEffect(() => {
    setAlertMessagesData({
      rechargeAlerts: 0,
      temperatureAlerts: 0,
      humidityAlerts: 0,
      fanAlerts: 0,
      solenoideAlerts: 0,
    })
    for (let i = 0; i < statusDetails.alertMessages.length; i++) {
      console.log(statusDetails.alertMessages[i].message)
      const message = statusDetails.alertMessages[i].message
      if (message) {
        if (message.includes("RECHARGE")) {
          setAlertMessagesData({
            ...alertMessagesData,
            rechargeAlerts: alertMessagesData.rechargeAlerts + 1,
          })
        } else if (message.includes("TEMEPERATURE")) {
          setAlertMessagesData({
            ...alertMessagesData,
            temperatureAlerts: alertMessagesData.temperatureAlerts + 1,
          })
        } else if (message.includes("HUMIDITY")) {
          setAlertMessagesData({
            ...alertMessagesData,
            humidityAlerts: alertMessagesData.humidityAlerts + 1,
          })
        } else if (message.includes("FAN")) {
          setAlertMessagesData({
            ...alertMessagesData,
            fanAlerts: alertMessagesData.fanAlerts + 1,
          })
        } else if (message.includes("SOLENOIDE")) {
          setAlertMessagesData({
            ...alertMessagesData,
            solenoideAlerts: alertMessagesData.solenoideAlerts + 1,
          })
        }
      }
    }
    console.log(alertMessagesData)
  }, [statusDetails.alertMessages])

  useEffect(() => {
    setErrorMessagesData({
      rechargeErrors: 0,
      temperatureErrors: 0,
      humidityErrors: 0,
      fanErrors: 0,
      solenoideErrors: 0,
    })
    for (let i = 0; i < statusDetails.errorMessages.length; i++) {
      console.log(statusDetails.errorMessages[i].message)
      const message = statusDetails.errorMessages[i].message
      if (message) {
        if (message.includes("RECHARGE")) {
          setErrorMessagesData({
            ...errorMessagesData,
            rechargeErrors: errorMessagesData.rechargeErrors + 1,
          })
        } else if (message.includes("TEMEPERATURE")) {
          setErrorMessagesData({
            ...errorMessagesData,
            temperatureerrors: errorMessagesData.temperatureErrors + 1,
          })
        } else if (message.includes("HUMIDITY")) {
          setErrorMessagesData({
            ...errorMessagesData,
            humidityErrors: errorMessagesData.humidityErrors + 1,
          })
        } else if (message.includes("FAN")) {
          setErrorMessagesData({
            ...errorMessagesData,
            fanErrors: errorMessagesData.fanErrors + 1,
          })
        } else if (message.includes("SOLENOIDE")) {
          setErrorMessagesData({
            ...errorMessagesData,
            solenoideErrors: errorMessagesData.solenoideErrors + 1,
          })
        }
      }
    }
    console.log(errorMessagesData)
  }, [statusDetails.errorMessages])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Breathers")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <h6>
            Last Updated On:{" "}
            <span className="fw-light">{`${new Date(
              statusDetails.lastUpdatedOn
            ).toDateString()} ${new Date(
              statusDetails.lastUpdatedOn
            ).toLocaleTimeString()}`}</span>{" "}
          </h6>
          <Row>
            <Status statusDetails={statusDetails} />
          </Row>
          <Row>
            <PieChart
              plottingValues1={[
                statusDetails.activeBreathers,
                statusDetails.alertMessages.length,
                statusDetails.errorMessages.length,
              ]}
              plottingValues2={[
                statusDetails.standbyBreathers,
                statusDetails.rechargingBreathers,
              ]}
            />
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Alerts</h4>
                  {/* <SimpleBar style={{ maxHeight: "390px" }}>
                    <ul className="list-group">
                      {statusDetails.alertMessages.map(data => {
                        return (
                          <li
                            key={data.id}
                            className="list-group-item border-0"
                          >
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <div className="avatar-xs">
                                  <span className="avatar-title rounded-circle bg-warning">
                                    <i className="mdi mdi-alert-circle bg-warning font-size-18"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="font-size-14">
                                  {`${data.message} in ${data.deviceDetails?.deviceName} of ${data.deviceDetails?.companyName}`}
                                </h5>
                                <p className="text-muted mb-0">
                                  {`${new Date(
                                    data.timeStamp
                                  ).toLocaleString()}`}
                                </p>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </SimpleBar> */}

                  <div className="d-flex flex-wrap column-gap-3 justify-content-start row-gap-3 p-3">
                    {Object.keys(alertMessagesData).map((data, index) => {
                      return (
                        // <div key={index}>
                        //   <p>
                        //     {data.toUpperCase().split("ALERTS")} :{" "}
                        //     {alertMessagesData[data]}
                        //   </p>
                        // </div>
                        <div className="col-xl-3 col-sm-6 col-12" key={index}>
                          <div className="card  bg-warning text-dark">
                            <div className="card-content">
                              <div className="card-body">
                                <div className="media d-flex justify-content-around">
                                  <div className="media-body text-right">
                                    <h3>{alertMessagesData[data]}</h3>
                                    <span>
                                      {data.toUpperCase().split("ALERTS")}
                                    </span>
                                  </div>
                                  <img
                                    src={iconsMapping[data.split("Alerts")[0]]}
                                    alt={data.split("Alerts")}
                                    className="w-25"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Col xl="1"/> */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Alarms</h4>
                  <div className="d-flex flex-wrap column-gap-3 justify-content-start row-gap-3 p-3">
                    {Object.keys(errorMessagesData).map((data, index) => {
                      return (
                        // <div key={index}>
                        //   <p>
                        //     {data.toUpperCase().split("ERRORS")} :{" "}
                        //     {errorMessagesData[data]}
                        //   </p>
                        // </div>

                        <div className="col-xl-3 col-sm-6 col-12" key={index}>
                          <div className="card  bg-danger text-dark">
                            <div className="card-content">
                              <div className="card-body">
                                <div className="media d-flex justify-content-around">
                                  <div className="media-body text-right">
                                    <h3>{errorMessagesData[data]}</h3>
                                    <span>
                                      {data.toUpperCase().split("ERRORS")}
                                    </span>
                                  </div>
                                  <img
                                    src={iconsMapping[data.split("Errors")[0]]}
                                    alt={data.split("Errors")}
                                    className="w-25"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
