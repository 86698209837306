import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row, Form } from "reactstrap"
import { Link } from "react-router-dom"
import axios from "axios"

export default function CardsDetails() {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }

  const [devices, setDevices] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])

  const handleInputChange = event => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)
    performSearch(newSearchTerm)
  }

  const performSearch = term => {
    const results = companies.filter(company =>
      company.name.toLowerCase().includes(term.toLowerCase())
    )
    setSearchResults(results)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/devices/devices-list`
        )
        console.log(response.data)
        setDevices(response.data)
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }
    fetchData()
  }, [])

  const filteredColumns = searchResults.length > 0 ? searchResults : devices
  return (
    <div>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>All Devices</h5>
            </div>
          </Col>
          <Col xl={9} sm={6}>
            <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      <Col xl="12">
        <Row>
          {filteredColumns.length > 0 ? (
            filteredColumns.map(device => (
              <Col key={device.id} sm="6">
                <Link
                  to={`/devicesdashboard/${device.connected_company}/${device._id}`}
                  className="text-black"
                >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Row>
                        <Col>
                          <h5>Name: {device.device_name}</h5>
                          <h5>Location: {device.device_location}</h5>
                          <h5>Type: {device.device_type}</h5>
                          <h5>Serial Id: {device.device_serialId}</h5>
                        </Col>
                        <Col className="text-right-end">
                          <h5 className="font-weight-bold">
                            Status:
                            <span className="text-success">Active</span>
                          </h5>
                          <h5>
                            Active Status:
                            <span> BR 2</span>
                          </h5>
                          <h5>Temp: 23 | Hum: 24</h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))
          ) : (
            <p>No matching companies found.</p>
          )}
        </Row>
      </Col>
    </div>
  )
}
