import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap"

document.title = "Yogya | Companies";

import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
import CompanyDetails from "./Companydetails";
import CompanyDevice from "./CompanyDevice";

const Companies = props => {
  // const [searchTerm, setSearchTerm] = useState('');
  // const [searchResults, setSearchResults] = useState([]);

  // const handleInputChange = (event) => {
  //   const newSearchTerm = event.target.value;
  //   setSearchTerm(newSearchTerm);
  //   performSearch(newSearchTerm);
  // };

  // const performSearch = (term) => {
  //   const results = data.filter(item => item.toLowerCase().includes(term.toLowerCase()));
  //   setSearchResults(results);
  // };

  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title={props.t("All")}
          breadcrumbItem={props.t("Companies")}
        />
      <Row>
      </Row>
      <CompanyDetails/>
      {/* <CompanyDevice/> */}
      </Container>
      </div>
    </React.Fragment>
    
  )
}

export default withTranslation()(Companies);