import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row, Form, Button } from "reactstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import notDeviceFound from "../../assets/images/flags/noDeviceFound.webp"
import { useNavigate } from "react-router-dom"

const h5Style = {
  fontWeight: "bolder",
}

const spanTextStyle = {
  fontSize: "16px",
}

export default function CompanyDevices() {
  const navigate = useNavigate()
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }

  const [devices, setDevices] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [company, setCompany] = useState([])

  const [companyID, setCompanyID] = useState()

  const [userRole, setUserRole] = useState("")
  const [userId, setUserId] = useState("")
  const [companyId, setCompanyId] = useState("")

  useEffect(() => {
    const getTokenDetails = async () => {
      try {
        const token = {
          token: localStorage.getItem("token"),
        }
        console.log(token)
        const response = await axios.post(
          `${baseUrl}/api/v1/dashboard/getTokenDetails`,
          token
        )

        console.log(response)
        if (response.data.error) {
          localStorage.clear()
          navigate("/admin-login")
        } else {
          setUserRole(response.data.role)
          setUserId(response.data.userId)

          if (userRole === "company-admin") {
            const userData = await axios.post(
              `${baseUrl}/api/v1/companyAuth/getCompanyIdFromUserId`,
              { userId: userId }
            )
            console.log(userData)
            if (userData.data.connected_company) {
              setCompanyId(userData.data.connected_company)
            }
          }

          setUserId(response.data.userId)
          console.log("The role is" + userRole)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getTokenDetails()
  }, [userRole == ""])

  useEffect(() => {
    const fetchData = async () => {
      const segment = window.location.href.split("/")
      const CompanyId = segment[segment.length - 1]
      setCompanyID(CompanyId)
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/devices/devices-list/${CompanyId}`
        )
        setDevices(response.data.companydevices)
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }
    fetchData()
  }, [companyID])

  useEffect(() => {
    const companyDetails = async () => {
      const segment = window.location.href.split("/")
      const Companyname = segment[segment.length - 1]
      console.log(Companyname)
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/companies/company-details/${Companyname}`
        )
        setCompany(response.data.Selectcompany)
        console.log(response.data.Selectcompany.name)
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }
    companyDetails()
  }, [])

  const handleInputChange = event => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)
    performSearch(newSearchTerm)
  }

  const performSearch = term => {
    const results = devices.filter(device =>
      device.device_name.toLowerCase().includes(term.toLowerCase())
    )
    setSearchResults(results)
    console.log(searchResults)
  }

  const filteredColumns = searchTerm > 0 ? searchResults : devices
  return (
    <div className="page-content">

      <Container fluid={true}>
        <Breadcrumbs
          title="companies"
          breadcrumbItem="Companies Control Devices"
        ></Breadcrumbs>
        <div>
          <Row className="mb-5">
            <Col xl={3} sm={6}>
              <div className="mt-2">
                <h5>{company.name}</h5>
              </div>
            </Col>

            <Col xl={9} sm={6}>
              <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                <div className="search-box mb-2 me-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleInputChange}
                      className="form-control bg-light border-light rounded"
                      placeholder="Search..."
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
        <Col xl="12">
          <Row>
            {filteredColumns.length > 0 ? (
              filteredColumns.map(device => (
                <Col key={device.id} sm="6">
                  <Link
                    to={`/devicesdashboard/${companyID}/${device._id}`}
                    className="text-black"
                  >
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Row>
                          <Col>
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "bolder" }}>Name:</h5>
                              &nbsp;
                              <span style={{ fontSize: "15px" }}>
                                {" "}
                                {device.device_name}
                              </span>
                            </div>
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "bolder" }}>
                                Location:
                              </h5>
                              &nbsp;
                              <span style={{ fontSize: "15px" }}>
                                {" "}
                                {device.device_location}
                              </span>
                            </div>
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "bolder" }}>Type:</h5>
                              &nbsp;
                              <span style={{ fontSize: "15px" }}>
                                {device.device_type}
                              </span>
                            </div>
                          </Col>
                          <Col className="text-right-end">
                            <h5
                              className="font-weight-bold"
                              style={{ fontWeight: "bolder" }}
                            >
                              Status:&nbsp;
                              <span className="text-success">Active</span>
                            </h5>
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "bolder" }}>
                                {" "}
                                Active Status:{" "}
                              </h5>
                              &nbsp;
                              <span style={{ fontSize: "15px" }}> BR 2</span>
                            </div>

                            <div className="d-flex">
                              <h5 style={{ fontWeight: "bolder" }}>
                                Serial Id:
                              </h5>
                              &nbsp;
                              <span style={{ fontSize: "15px" }}>
                                {" "}
                                {device.device_serialId}
                              </span>
                            </div>

                            {/* <h5>Created Date:{device.device_createdDate}</h5> */}
                            {/* <div className="d-flex">
                              <h5 style={{ fontWeight: "bolder" }}>Temp:</h5>
                              &nbsp;
                              <span style={{ fontSize: "15px" }}> 23 </span>
                              &nbsp;|&nbsp;
                              <h5 style={{ fontWeight: "bolder" }}> Hum:</h5>
                              &nbsp;
                              <span style={{ fontSize: "15px" }}> 24 </span>
                            </div> */}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              ))
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <img
                    src={notDeviceFound}
                    className="w-50"
                    alt="No Device Found"
                  />
                </div>
                <h2 className="text-center">No Devices Found.</h2>
              </>
            )}
          </Row>
        </Col>
      </Container>
    </div>
  )
}
