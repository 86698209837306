import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux

import { Link, useNavigate } from "react-router-dom"
// import withRouter from "components/Common/withRouter";

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

const Resetpassword = props => {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [token, setToken] = useState("")
  const [userId, setuserID] = useState("")
  const [resetStatus, setResetStatus] = useState("")
  const [error, seterror] = useState("")
  const [Hidden, setHidden] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    // Fetch the token from the URL params
    const fetchData = async () => {
      const segment = window.location.href.split("/")
      const token = segment[segment.length - 1]
      const userId = segment[segment.length - 2]
      console.log(window.location.href)
      console.log(segment)
      console.log("userId", userId)
      console.log("token", token)
      console.log(window.location.href)
      setToken(token)
      setuserID(userId)
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/companyAuth/company-resetpassword/${userId}/${token}`
        )
        setResetStatus("")
        setHidden(false)
      } catch (error) {
        setHidden(true)
        console.log(error.response.data)
        seterror("Invalid Link.")
        console.log(error)
      }
    }
    fetchData()
  }, [])

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  const handleConfirmPasswordChange = event => {
    setConfirmPassword(event.target.value)
  }

  const handleResetPassword = async event => {
    event.preventDefault()

    if (password !== confirmPassword) {
      seterror("Passwords do not match")
      return
    } else {
      if (password !== undefined && password.length >= 6) {
        try {
          const response = await axios.post(
            `${baseUrl}/api/v1/companyAuth/company-resetpassword/${userId}/${token}`,
            {
              password: password,
            }
          )
          setResetStatus(response.data.message)
          navigate("/company-login")
        } catch (e) {
          console.log(e.response.data.message)
          seterror(e.response.data.message)
          seterror("")
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password</h5>
                        {/* <p>Sign in to continue to YOGYA.</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    {error != "" ? <Alert color="danger">{error}</Alert> : null}
                    {resetStatus != "" ? (
                      <Alert color="success">{resetStatus}</Alert>
                    ) : null}
                  </div>
                  {Hidden == false && (
                    <div className="p-2">
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="Password"
                            className="form-control"
                            placeholder="Enter the new password"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="password"
                            value={confirmPassword}
                            type="password"
                            placeholder="Enter the confirm password"
                            onChange={handleConfirmPasswordChange}
                          />
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={handleResetPassword}
                            type="button"
                          >
                            Reset password
                          </button>
                        </div>
                      </Form>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Resetpassword
