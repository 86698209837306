import PropTypes from "prop-types"
import React from "react"

import axios from "axios"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
// import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

const AdminLogin = props => {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }

  const [selectedValue, setSelectedValue] = React.useState("a")

  const navigate = useNavigate()

  const handleChange = event => {
    setSelectedValue(event.target.value)
  }

  //meta title
  document.title = "Yogya | Company Login"

  const [email, setEmail] = React.useState(undefined)
  const [password, setPassword] = React.useState(undefined)

  const isEmailValid = email => {
    // Simple email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const onUserFormSubmission = async () => {
    if (
      email !== undefined &&
      password !== undefined &&
      isEmailValid(email) &&
      password.length > 6
    ) {
      try {
        let response = await axios.post(
          `${baseUrl}/api/v1/companyAuth/company-login`,
          {
            email: email,
            password: password,
          }
        )
        console.log(response)
        console.log(response.status)
        console.log(response.data)
        if (
          response.status === 200 &&
          response.data !== undefined &&
          response.data.token !== undefined
        ) {
          localStorage.setItem("token", response.data.token)
          localStorage.setItem("logintype", "company-login")
          navigate("/dashboard")
        } else {
          setError("Something went wrong. Try again later.")
        }
      } catch (e) {
        if (
          e.response !== undefined &&
          e.response.data !== undefined &&
          e.response.data.msg !== undefined
        ) {
          setError(e.response.data.msg)
        }
        console.log("Something went wrong. Try again later.")
      }
    } else {
      setError("Enter email and password to continue")
      return undefined
    }
  }

  const [error, setError] = React.useState("")

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">User Login !</h5>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form className="form-horizontal">
                      {error != "" ? (
                        <Alert color="danger">
                          {error?.replace("please , Signup", "")}
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={password}
                          type="password"
                          placeholder="Enter Password"
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="mt-4 text-center">
                        <Link
                          to="/company-forgot-password"
                          className="text-muted"
                        >
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          onClick={onUserFormSubmission}
                          type="button"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default AdminLogin
