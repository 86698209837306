import React, { useState, useEffect } from "react"
import axios from "axios"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import FormUpload from "./FormUpload"

const DeviceControl = props => {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }

  const [controlDevicevalue, setcontrolDevicevalue] = useState({})

  const [companyName, setCompanyName] = useState("")
  const [deviceName, setDeviceName] = useState("")

  const [userRole, setUserRole] = useState("")
  const [userId, setUserId] = useState("")

  const [lastUpdated, setLastUpdated] = useState("")

  useEffect(() => {
    const getTokenDetails = async () => {
      try {
        const token = {
          token: localStorage.getItem("token"),
        }
        const response = await axios.post(
          `${baseUrl}/api/v1/dashboard/getTokenDetails`,
          token
        )
        if (response.data.error) {
          localStorage.clear()
          navigate("/admin-login")
        } else {
          setUserRole(response.data.role)
          setUserId(response.data.userId)
          console.log(userRole)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getTokenDetails()
  }, [userRole == ""])

  const [br1Details, setBr1Details] = useState({
    value: "",
    sIn: "",
    sOut: "",
    sFan: "",
    isValueChanged: false,
    isAnySChanged: false,
  })

  const [br2Details, setBr2Details] = useState({
    value: "",
    sIn: "",
    sOut: "",
    sFan: "",
    isValueChanged: false,
    isAnySChanged: false,
  })

  const [temperatureDetails, setTemparatureDetails] = useState({
    onAt: 0,
    offAt: 0,
    isOnChanged: false,
    isOffChanged: false,
  })

  const [csvDetails, setCsvDetails] = useState({
    br1_fan: br1Details.value,
    br1_sin: br1Details.sIn,
    br1_sout: br1Details.sOut,
    br1_sfan: br1Details.sFan,
    br2_fan: br2Details.value,
    br2_sin: br2Details.sIn,
    br2_sout: br2Details.sOut,
    br2_sfan: br2Details.sFan,
    last_updated_on: new Date(),
  })

  const inputStyle = {
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    MozAppearance: "textfield",
    appearance: "textfield",
    width: "100%",
  }

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${baseUrl}/api/v1/control/control-list/${deviceId}`
      )

      setLastUpdated(response.data.updatedAt)
      setBr1Details({
        ...br1Details,
        value: response.data.br1.br1_fan,
        sIn: response.data.br1.s1_in,
        sOut: response.data.br1.s1_out,
        sFan: response.data.br1.s1_fan,
      })

      setBr2Details({
        ...br2Details,
        value: response.data.br2.br2_fan,
        sIn: response.data.br2.s2_in,
        sOut: response.data.br2.s2_out,
        sFan: response.data.br2.s2_fan,
      })
      setcontrolDevicevalue(response.data)

      setTemparatureDetails({
        ...temperatureDetails,
        onAt: response.data.heater.turnon_temperature,
        offAt: response.data.heater.turnoff_temperature,
      })
    }
    getData()
  }, [])

  useEffect(() => {
    setCsvDetails({
      ...csvDetails,
      br1_fan: br1Details.value,
      br1_sin: br1Details.sIn,
      br1_sout: br1Details.sOut,
      br1_sfan: br1Details.sFan,
      br2_fan: br2Details.value,
      br2_sin: br2Details.sIn,
      br2_sout: br2Details.sOut,
      br2_sfan: br2Details.sFan,
      last_updated_on: new Date(lastUpdated),
    })
  }, [br1Details])

  const segment = window.location.href.split("/")
  const deviceId = segment[segment.length - 1]
  const device_type = segment[segment.length - 2]

  const fetchData = async () => {
    const segment = window.location.href.split("/")
    const id = segment[segment.length - 1]

    const response = await axios.put(
      `${baseUrl}/api/v1/control/control-list/${id}`,
      {
        br1_fan: br1Details.value,
        s1_in: br1Details.sIn,
        s1_out: br1Details.sOut,
        s1_fan: br1Details.sFan,
        br2_fan: br2Details.value,
        s2_in: br2Details.sIn,
        s2_out: br2Details.sOut,
        s2_fan: br2Details.sFan,
        turnon_temperature: turnonTemperature,
        turnoff_temperature: turnoffTemperature,
        connected_device: deviceId,
      }
    )
    console.log(response.data)
  }

  document.title = "Yogya | DeviceControl"

  useEffect(() => {
    const companyDetails = async () => {
      const segment = window.location.href.split("/")
      const CompanyID = segment[segment.length - 2]
      console.log(CompanyID)
      try {
        const response1 = await axios.get(
          `${baseUrl}/api/v1/companies/company-details/${CompanyID}`
        )
        setCompanyName(response1.data.Selectcompany.name)
        console.log(response1.data.Selectcompany.name)
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }
    companyDetails()
  }, [])

  useEffect(() => {
    const companyDetails = async () => {
      const segment = window.location.href.split("/")
      const DeviceID = segment[segment.length - 1]
      try {
        const response2 = await axios.get(
          `${baseUrl}/api/v1/devices/device-name/${DeviceID}`
        )
        setDeviceName(response2.data.deviceName.device_name)

        console.log(response2.data.deviceName.device_name)
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }
    companyDetails()
  }, [])

  function downloadCSV() {
    const data = csvDetails
    console.log("************************************************");
    console.log(csvData);
    const csvData = convertToCSV(data)

    const blob = new Blob([csvData], { type: "text/csv" })
    const url = URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = url
    link.download = "data.csv"
    link.click()

    URL.revokeObjectURL(url)
  }

  function convertToCSV(data) {
    const headers = Object.keys(data)
    const row = headers.map(header => data[header])
    const csvRow = row.join(",")

    return `${headers.join(",")}\n${csvRow}`
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <p>{JSON.stringify(controlDevicevalue.br1)}</p> */}
          <Breadcrumbs
            title="Device"
            breadcrumbItem="Control Devices"
          ></Breadcrumbs>
          {/* ======================================================= */}
          <div className={userRole === "company-admin" ? "d-none" : "d-block"}>
            <div className="d-flex my-2 justify-content-between align-items-center">
              <h5 className="pb-3">{`${companyName} / ${deviceName}`} </h5>
              <Button onClick={downloadCSV}>Download XLSX</Button>
            </div>
            <h6 className="mb-3">
              Last Updated On:{" "}
              <span className="fw-light">{`${new Date(
                lastUpdated
              ).toDateString()} ${new Date(
                lastUpdated
              ).toLocaleTimeString()}`}</span>{" "}
            </h6>
            <Row>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Br1 Control Device</CardTitle>
                    <Form>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          BR 1 Fan
                        </Label>
                        <div className="d-flex">
                          <Input
                            name="br1fan"
                            type="number"
                            width={70}
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Input Field"
                            value={br1Details.value}
                            onChange={e => {
                              setBr1Details({
                                ...br1Details,
                                value: e.target.value,
                                isValueChanged: true,
                              })
                            }}
                            style={inputStyle}
                          ></Input>
                          <Col xl="1" />
                          <Button
                            disabled={br1Details.isValueChanged ? false : true}
                            onClick={fetchData}
                          >
                            update
                          </Button>
                        </div>
                      </div>
                      <div className="mb-3 mt-2">
                        <div className="d-flex form-check">
                          <h5>Status:</h5>&nbsp;&nbsp;
                          <p>FAN - OFF |</p>&nbsp;
                          <p>SIN - ON |</p>&nbsp;
                          <p>SOUT - OFF |</p>&nbsp;
                          <p>SFAN - ON </p>
                        </div>
                      </div>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputCity">S IN</Label>
                            <Input
                              name="sin"
                              type="number"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Value"
                              value={br1Details.sIn}
                              onChange={e => {
                                setBr1Details({
                                  ...br1Details,
                                  sIn: e.target.value,
                                  isAnySChanged: true,
                                })
                              }}
                              style={inputStyle}
                            ></Input>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">S OUT</Label>
                            <Input
                              name="sout"
                              type="number"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Value"
                              value={br1Details.sOut}
                              onChange={e => {
                                setBr1Details({
                                  ...br1Details,
                                  sOut: e.target.value,
                                  isAnySChanged: true,
                                })
                              }}
                              style={inputStyle}
                            ></Input>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">S FAN</Label>
                            <Input
                              name="sfan"
                              type="number"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Value"
                              value={br1Details.sFan}
                              onChange={e => {
                                setBr1Details({
                                  ...br1Details,
                                  sFan: e.target.value,
                                  isAnySChanged: true,
                                })
                              }}
                              style={inputStyle}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                      <Button
                        disabled={br1Details.isAnySChanged ? false : true}
                        onClick={fetchData}
                      >
                        Update
                      </Button>
                      <div></div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Br 2 Control Device</CardTitle>

                    <Form>
                      <div className="mb-3">
                        <Label htmlFor="formrow-firstname-Input">
                          BR 2 Fan
                        </Label>
                        <div className="d-flex">
                          <Input
                            name="br1_fan"
                            type="number"
                            // width={70}
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Input field"
                            style={inputStyle}
                            value={br2Details.value}
                            onChange={e =>
                              setBr2Details({
                                ...br2Details,
                                value: e.target.value,
                                isValueChanged: true,
                              })
                            }
                          ></Input>
                          <Col xl="1" />
                          <Button
                            disabled={br2Details.isValueChanged ? false : true}
                            onClick={fetchData}
                          >
                            update
                          </Button>
                        </div>
                      </div>
                      <div className="mb-3 mt-2">
                        <div className="form-check">
                          <div className="mb-3">
                            <div className="d-flex pl-5 form-check">
                              <h5>Status:</h5>&nbsp;&nbsp;
                              <p>FAN - ON |</p>&nbsp;
                              <p>SIN - OFF |</p> &nbsp;
                              <p>SOUT - ON |</p> &nbsp;
                              <p>SFAN - OFF </p>&nbsp;
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputCity">S IN</Label>
                            <Input
                              name="s_in"
                              type="number"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Value"
                              style={inputStyle}
                              value={br2Details.sIn}
                              onChange={e =>
                                setBr2Details({
                                  ...br2Details,
                                  sIn: e.target.value,
                                  isAnySChanged: true,
                                })
                              }
                            ></Input>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">S OUT</Label>
                            <Input
                              name="s_out"
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Value"
                              style={inputStyle}
                              value={br2Details.sOut}
                              onChange={e =>
                                setBr2Details({
                                  ...br2Details,
                                  sOut: e.target.value,
                                  isAnySChanged: true,
                                })
                              }
                            ></Input>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">S FAN</Label>
                            <Input
                              name="s_fan"
                              type="number"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Value"
                              style={inputStyle}
                              value={br2Details.sFan}
                              onChange={e =>
                                setBr2Details({
                                  ...br2Details,
                                  sFan: e.target.value,
                                  isAnySChanged: true,
                                })
                              }
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                      <Button
                        disabled={br2Details.isAnySChanged ? false : true}
                        onClick={fetchData}
                      >
                        Update
                      </Button>
                      <div></div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5>Recharge Heater Settings</h5>
                      <Col xl={6}>
                        <div className="d-flex mt-2">
                          <Col xl={5} sm={12}>
                            <p>Turn On Temperature</p>
                          </Col>
                          <Col xl={9} sm={6}>
                            <Form className="mt-4 mt-sm-0 d-flex align-items-center">
                              <div className="mb-2 me-2">
                                <div className="position-relative">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="formrow-InputCity"
                                    placeholder="Value"
                                    style={inputStyle}
                                    value={temperatureDetails.onAt}
                                    onChange={e =>
                                      setTemparatureDetails({
                                        ...temperatureDetails,
                                        onAt: e.target.value,
                                        isOnChanged: true,
                                      })
                                    }
                                  ></Input>
                                  {/* <i className="bx bx-search-alt search-icon"></i> */}
                                </div>
                              </div>
                              <div className="search-box mb-2 me-2">
                                <div className="position-relative">
                                  <Button
                                    disabled={
                                      temperatureDetails.isOnChanged
                                        ? false
                                        : true
                                    }
                                    onClick={fetchData}
                                  >
                                    Update
                                  </Button>
                                  {/* <i className="bx bx-search-alt search-icon"></i> */}
                                </div>
                              </div>
                            </Form>
                          </Col>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="d-flex mt-2">
                          <Col xl={5} sm={12}>
                            <p>Turn Off Temperature</p>
                          </Col>
                          <Col xl={9} sm={6}>
                            <Form className="mt-4 mt-sm-0 d-flex">
                              <div className="mb-2 me-2">
                                <div className="position-relative">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="formrow-InputZip"
                                    placeholder="Value"
                                    style={inputStyle}
                                    value={temperatureDetails.offAt}
                                    onChange={e =>
                                      setTemparatureDetails({
                                        ...temperatureDetails,
                                        offAt: e.target.value,
                                        isOffChanged: true,
                                      })
                                    }
                                  ></Input>
                                  {/* <i className="bx bx-search-alt search-icon"></i> */}
                                </div>
                              </div>
                              <div className="search-box mb-2 me-2">
                                <div className="position-relative">
                                  <Button
                                    disabled={
                                      temperatureDetails.isOffChanged
                                        ? false
                                        : true
                                    }
                                    onClick={fetchData}
                                  >
                                    Update
                                  </Button>
                                  {/* <i className="bx bx-search-alt search-icon"></i> */}
                                </div>
                              </div>
                            </Form>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
          {/* ======================================================= */}
          <FormUpload />
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default DeviceControl
