import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Form, Button } from "reactstrap"

export default function DeviceStatus({ companyAndDeviceId, deviceData }) {
  return (
    <div>
      <Col xl="12">
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>Statistics</h5>
            </div>
          </Col>
          <Col xl={9} sm={6}>
            <Form className="mt-4 mt-sm-0 d-flex align-items-center">
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <Button className="py-0">
                    <Link
                      className="text-light text-size-15 "
                      to={`/controldevices/${companyAndDeviceId.companyId}/${companyAndDeviceId.deviceId}`}
                    >
                      Control
                    </Link>
                  </Button>
                  {/* <i className="bx bx-search-alt search-icon"></i> */}
                </div>
              </div>
              <Col xs={3} />
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <Button className="py-0">
                    <Link className="text-light text-size-15 " to="#">
                      View Alerts
                    </Link>
                  </Button>
                  {/* <i className="bx bx-search-alt search-icon"></i> */}
                </div>
              </div>
              <Col xs={3} />
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <Button className="py-0">
                    <Link className="text-light text-size-15 " to="#">
                      View Alarms
                    </Link>
                  </Button>
                  {/* <i className="bx bx-search-alt search-icon"></i> */}
                </div>
              </div>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-bitcoin h2 text-warning mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <h1 className={deviceData.BR1_STATUS == "ACTIVE" ? `text-success mb-2` : deviceData.BR1_STATUS == "RECHARGE" ? `text-warning mb-2` : `text-danger mb-2`}>{deviceData.BR1_STATUS}</h1>
                    <p>{deviceData.BR1_STATUS == "ACTIVE" ? "" : `Temp: ${deviceData.otherTemp}C | Humidity: ${deviceData.otherHumi}%`}</p>
                    <p className={deviceData.BR1_STATUS == "ACTIVE" ? "mt-5" : "mt-3"}>BR Left State</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-ethereum h2 text-primary mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <h1 className={deviceData.BR2_STATUS == "ACTIVE" ? `text-success mb-2` : deviceData.BR2_STATUS == "RECHARGE" ? `text-warning mb-2` : `text-danger mb-2`}>{deviceData.BR2_STATUS}</h1>
                    <p>{deviceData.BR2_STATUS == "ACTIVE" ? "" : `Temp: ${deviceData.otherTemp} C | Humidity: ${deviceData.otherHumi}%`}</p>
                    <p className="mt-3">BR Right State</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-litecoin h2 text-info mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <h1 className="text-muted mb-2">
                      {deviceData.humidity ? `${deviceData.humidity} %` : "N/A"}
                    </h1>
                    <p className="mt-5">Humidity of Active Breather</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-litecoin h2 text-info mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <h1 className="text-muted mb-2">
                      {deviceData.temperature
                        ? `${deviceData.temperature}`
                        : "N/A"}{" "}
                      &deg; C
                    </h1>
                    <p className="mt-5">Temp of Active Breather</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-bitcoin h2 text-warning mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <h1 className="text-muted mb-2">
                      {deviceData.BR1_LIFE === 0
                        ? "N/A"
                        : deviceData.BR1_LIFE + "%"}{" "}
                    </h1>
                    <p className="mt-5">Left Breather Balance Life</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  {/* <div className="me-3 align-self-center">
                            <i className="mdi mdi-ethereum h2 text-primary mb-0" />
                          </div> */}
                  <div className="flex-grow-1">
                    <h1 className="text-muted mb-2">
                      {deviceData.BR2_LIFE === 0
                        ? "N/A"
                        : deviceData.BR2_LIFE + "%"}
                    </h1>
                    <p className="mt-5">Right Breather Balance Life</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  
                  <div className="flex-grow-1">
                    <h1 className="text-muted mb-2">
                      {deviceData.BR1_CYCLE === 0
                        ? "N/A"
                        : deviceData.BR1_CYCLE}
                    </h1>
                    <p className="mt-5">Left Cycle</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  
                  <div className="flex-grow-1">
                    <h1 className="text-muted mb-2">
                      {deviceData.BR2_CYCLE === 0
                        ? "N/A"
                        : deviceData.BR2_CYCLE}
                    </h1>
                    <p className="mt-5">Right Cycle</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Col>
    </div>
  )
}
