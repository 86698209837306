import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { ArrowLeft } from "lucide-react"

const Breadcrumb = ({ title, breadcrumbItem }) => {
  const navigate = useNavigate()

  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {breadcrumbItem == 'Dashboard' || breadcrumbItem == 'Companies' ? null : <ArrowLeft style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />}
            <h4 className="mb-sm-0 font-size-18">{breadcrumbItem} </h4>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#" onClick={() => navigate(-1)}>
                  {title}
                </Link>
              </BreadcrumbItem>

              <BreadcrumbItem active>
                <Link to="#">{breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
