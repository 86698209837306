import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row, Form } from "reactstrap"
import { Link } from "react-router-dom"
import CompanyDevice from "./CompanyDevice"
import axios from "axios"

export default function CompanyDetails() {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/v1/companies/company-list`
        )
        console.log(response.data)
        setCompanies(response.data)
      } catch (error) {
        console.log("Failed to retrieve company data", error)
      }
    }
    fetchData()
  }, [])

  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])

  const handleInputChange = event => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)
    performSearch(newSearchTerm)
  }

  const performSearch = term => {
    const results = companies.filter(company =>
      company.name.toLowerCase().includes(term.toLowerCase())
    )
    setSearchResults(results)
  }

  const filteredColumns = searchTerm ? searchResults : companies
  console.log("-----------------")
  console.log(filteredColumns)
  console.log("-----------------")

  return (
    <div>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>All Companies</h5>
            </div>
          </Col>
          <Col xl={9} sm={6}>
            <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
      <Col xl="12">
        <Row>
          {filteredColumns.length > 0 ? (
            filteredColumns.map(company => (
              <Col key={company.id} sm="6">
                <Link
                  to={`/companydevices/${company._id}`}
                  className="text-black"
                >
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Row>
                        <Col>
                          <h5>{company.name}</h5>
                          <h5 className="mt-5">
                            12 Active | 4 Alerts | 3 Alarms
                          </h5>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))
          ) : (
            <p>No matching companies found.</p>
          )}
        </Row>
      </Col>
    </div>
  )
}
