import React from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Row,
  UncontrolledDropdown,
} from "reactstrap"

document.title = "Yogya | Devices";

import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next";
import CardsDetails from "./CardsDetails";
import DeviceDashboard from "./DeviceDashboard";
import DeviceControl from "./DeviceControl";
const Device = props => {
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs
          title={props.t("Devices")}
          breadcrumbItem={props.t("All Devices")}
        />
      <div>
        {/* <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>All Devices</h5>
            </div>
          </Col>
          <Col xl={9} sm={6}>
            <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
              <div className="search-box mb-2 me-2">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control bg-light border-light rounded"
                    placeholder="Search..."
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </Form>
          </Col>
        </Row> */}
      </div>
      <Row>
        <CardsDetails/>
      </Row>
      </Container>
      </div>
    </React.Fragment>
    
  )
}

export default withTranslation()(Device);
