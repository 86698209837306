import React, { useEffect, useState, useRef } from "react"
import { Container, Row, Col, Form, Button } from "reactstrap"
import axios from "axios"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { exportToExcel } from 'react-json-to-excel';

//Pages

import MultiLineChart from "./MultilineChart"
import Status from "./Status"

//i18n
import { withTranslation } from "react-i18next"

const DeviceDashboard = props => {
  let baseUrl = ""
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL
      break
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL
      break
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL
      break
  }
  //meta title
  document.title = "Yogya | Dashboard"

  const convertToIST = (inputDateTime) => {
    const utcDateTime = new Date(inputDateTime);
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+05:30
    const ISTDateTime = new Date(utcDateTime.getTime() + ISTOffset);

    const year = ISTDateTime.getUTCFullYear();
    const month = String(ISTDateTime.getUTCMonth() + 1).padStart(2, '0');
    const day = String(ISTDateTime.getUTCDate()).padStart(2, '0');
    const hours = String(ISTDateTime.getUTCHours()).padStart(2, '0');
    const minutes = String(ISTDateTime.getUTCMinutes()).padStart(2, '0');
    const seconds = String(ISTDateTime.getUTCSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const [plotDates, setPlotDates] = useState({
    start: convertToIST(new Date(new Date().setDate(new Date().getDate() - 1))),
    end: convertToIST(new Date()),
    active: "oneDay",
  })

  const [customDate, setCustomDate] = useState({
    start: "",
    end: "",
    s: "",
    e: ""
  })

  const [plotBreather, setPlotBreather] = useState("BR_1")

  const [companyAndDeviceID, setCompanyAndDeviceID] = useState({
    companyId: "",
    deviceId: "",
  })

  const [companyName, setCompanyName] = useState("")
  const [deviceName, setDeviceName] = useState("")

  const [deviceData, setDeviceData] = useState({
    BR1_STATUS: "N/A",
    BR2_STATUS: "N/A",
    humidity: 0,
    temperature: 0,
    BR1_LIFE: 0,
    BR2_LIFE: 0,
    BR1_CYCLE: 0,
    BR2_CYCLE: 0,
    lastUpdatedOn: "",
    otherTemp: 0,
    otherHumi: 0
  })

  useEffect(() => {
    const segment = window.location.href.split("/")
    setCompanyAndDeviceID({
      ...companyAndDeviceID,
      companyId: segment[segment.length - 2],
      deviceId: segment[segment.length - 1],
    })
  }, [])

  useEffect(() => {
    const companyDetails = async () => {
      const segment = window.location.href.split("/")
      const CompanyID = segment[segment.length - 2]
      try {
        const response1 = await axios.get(
          `${baseUrl}/api/v1/companies/company-details/${CompanyID}`
        )
        setCompanyName(response1.data.Selectcompany.name)
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }
    companyDetails()
  }, [])

  useEffect(() => {
    const companyDetails = async () => {
      const segment = window.location.href.split("/")
      const DeviceID = segment[segment.length - 1]
      try {
        const response2 = await axios.get(
          `${baseUrl}/api/v1/devices/device-name/${DeviceID}`
        )
        setDeviceName(response2.data.deviceName.device_name)

      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }
    companyDetails()
  }, [])

  useEffect(() => {
    const deviceDetails = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/api/v1/dashboard/getDeviceDashBoardDetails/${companyAndDeviceID.deviceId}`
        )
        setDeviceData({
          ...deviceData,
          BR1_STATUS: response.data.BR1_STATUS,
          BR2_STATUS: response.data.BR2_STATUS,
          humidity: response.data.humidity,
          temperature: response.data.temperature,
          BR1_LIFE: response.data.BR1_LIFE,
          BR2_LIFE: response.data.BR2_LIFE,
          BR1_CYCLE: response.data.BR1_CYCLE,
          BR2_CYCLE: response.data.BR2_CYCLE,
          lastUpdatedOn: response.data.lastUpdatedOn,
          otherTemp: response.data.otherTemp,
          otherHumi: response.data.otherHumi
        })
      } catch (error) {
        console.log("Failed to retrieve devices data", error)
      }
    }


    deviceDetails()
    const interval = setInterval(() => {
      deviceDetails()
    }, 30000)
    return () => clearInterval(interval)
  }, [companyAndDeviceID])




  function setLastWeek() {
    setPlotDates({
      ...plotDates,
      start: convertToIST(new Date(new Date().setDate(new Date().getDate() - 7))),
      end: convertToIST(new Date()),
      active: "oneWeek",
    })
  }



  async function downloadCSV() {
    const response = await axios.get(
      `${baseUrl}/api/v1/dashboard/getCSVData/${companyAndDeviceID.deviceId}`
    )
    let samplejson = response.data
    samplejson.map((data) => {
      delete data._id
      return data
    })
    console.log(samplejson);
    exportToExcel(samplejson, 'downloadfilename')
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Devices")}
            breadcrumbItem={props.t("Devices Dashboard")}
          />

          <Row>
            <h5 className="pb-3">{`${companyName} / ${deviceName}`}</h5>
          </Row>
          <h6 className="mb-3">
            Last Updated On:{" "}
            <span className="fw-light">{`${new Date(
              deviceData.lastUpdatedOn
            ).toDateString()} ${new Date(
              deviceData.lastUpdatedOn
            ).toLocaleTimeString()}`}</span>{" "}
          </h6>
          <Row>
            <Status
              companyAndDeviceId={companyAndDeviceID}
              deviceData={deviceData}
            />
          </Row>
          <Row>
            <div>
              <Row className="mb-3">
                <Col xl={3} sm={6}>
                  <div className="mt-2">
                    <h5>Filter By</h5>
                  </div>
                </Col>
                <Col xl={9} sm={6}>
                  <Form className="mt-4 mt-sm-0 d-flex align-items-center">
                    <div className="search-box mb-2 me-2">
                      <div className="position-relative">
                        <Button
                          className={
                            plotDates.active === "oneDay" ? "bg-dark" : ""
                          }
                          onClick={() => {
                            setPlotDates({
                              ...plotDates,
                              start: convertToIST(new Date(new Date().setDate(new Date().getDate() - 1))),
                              end: convertToIST(new Date()),
                              active: "oneDay",
                            })
                          }}
                        >
                          Last one day
                        </Button>
                        {/* <i className="bx bx-search-alt search-icon"></i> */}
                      </div>
                    </div>
                    <div className="search-box mb-2 me-2">
                      <div className="position-relative">
                        <Button
                          className={
                            plotDates.active === "oneWeek" ? "bg-dark" : ""
                          }
                          onClick={setLastWeek}
                        >
                          Last one week
                        </Button>
                        {/* <i className="bx bx-search-alt search-icon"></i> */}
                      </div>
                    </div>
                    <div className="search-box mb-2 me-2">
                      <div className="position-relative">
                        <Button
                          className={
                            plotDates.active === "oneMonth" ? "bg-dark" : ""
                          }
                          onClick={() => {
                            setPlotDates({
                              ...plotDates,
                              start: convertToIST(new Date(new Date().setDate(new Date().getDate() - 30))),
                              end: convertToIST(new Date()),
                              active: "oneMonth",
                            })
                          }}
                        >
                          Last one month
                        </Button>
                        {/* <i className="bx bx-search-alt search-icon"></i> */}
                      </div>
                    </div>
                    <div className="mb-2 me-2">
                      <div className="position-relative">
                        <Button
                          disabled
                          className={
                            plotDates.active === "custom" ? "bg-dark" : ""
                          }
                        >
                          Custom
                        </Button>
                        {/* <i className="bx bx-search-alt search-icon"></i> */}
                      </div>
                    </div>
                    <Col xs={2} />
                  </Form>
                </Col>
              </Row>
            </div>
            <div>
              <Row className="mb-3">
                <Col xl={3} sm={6}>
                  <div className="mt-2">
                    <h5>Date Range</h5>
                  </div>
                </Col>
                <Col xl={9} sm={6}>
                  <Form className="mt-4 mt-sm-0 d-flex">
                    <div className="search-box mb-2 me-2">
                      <div className="position-relative">
                        <input
                          type="date"
                          className="form-control bg-light border-light rounded"
                          placeholder="Start Date"
                          value={customDate.s}
                          onChange={e => {
                            setCustomDate({
                              ...customDate,
                              s: e.target.value,
                              start: `${e.target.value} 00:00:00`,
                            })
                          }}
                        />
                        {/* <i className="bx bx-search-alt search-icon"></i> */}
                      </div>
                    </div>
                    <div className="search-box mb-2 me-2">
                      <div className="position-relative">
                        <input
                          type="date"
                          className="form-control bg-light border-light rounded"
                          placeholder="End date"
                          value={customDate.e}
                          onChange={e => {
                            setCustomDate({
                              ...customDate, e: e.target.value,
                              end: `${e.target.value} 00:00:00`,
                            })
                          }}
                        />
                        {/* <i className="bx bx-search-alt search-icon"></i> */}
                      </div>
                    </div>
                    <div className="search-box mb-2 me-2">
                      <div className="position-relative">
                        <Button
                          disabled={
                            customDate.start === "" || customDate.end === ""
                          }
                          onClick={() => {
                            setPlotDates({
                              ...plotDates,
                              start:
                                customDate.start,
                              end:
                                customDate.end,
                              active: "custom",
                            })
                          }}
                        >
                          Fetch
                        </Button>
                        {/* <i className="bx bx-search-alt search-icon"></i> */}
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
              <buttton onClick={downloadCSV} className="btn bg-dark text-light">Download XLSX</buttton>
            </div>

          </Row>
          <Row>
            <MultiLineChart
              deviceId={companyAndDeviceID.deviceId}
              plotDates={plotDates}
              activeBreather={plotBreather}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DeviceDashboard)
