import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import AdminLogin from "../pages/Authentication/AdminLogin"
import UserLogin from "../pages/Authentication/UserLogin"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Resetpassword from "pages/Authentication/Resetpassword"
import UserResetPassword from "../pages/Authentication/UserResetPassword"
import ComapanyForgetPwd from "../pages/Authentication/UserForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ViewAlert from "../pages/Dashboard/Alert"
import ViewAlarm from "../pages/Dashboard/Alarm"

//device
import Device from "../pages/Device/index"
import DeviceControl from "../pages/Device/DeviceControl"
import DeviceDashboard from "pages/Device/DeviceDashboard"

//Company
import Companies from "../pages/Companies/index"
import CompanyDevice from "../pages/Companies/CompanyDevice"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/viewalert", component: <ViewAlert /> },
  { path: "/viewalarm", component: <ViewAlarm /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Device
  { path: "/devices", component: <Device /> },
  {
    path: "/controldevices/:companyId/:deviceId",
    component: <DeviceControl />,
  },
  {
    path: "/devicesdashboard/:companyId/:deviceId",
    component: <DeviceDashboard />,
  },

  //Companies
  { path: "/companies", component: <Companies /> },
  { path: "/companydevices/:CompanyId", component: <CompanyDevice /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/admin-login", component: <AdminLogin /> },
  { path: "/company-login", component: <UserLogin /> },
  { path: "/admin-forgot-password", component: <ForgetPwd /> },
  {
    path: "/admin-reset-password/:userId/:token",
    component: <Resetpassword />,
  },
  {
    path: "/company-reset-password/:userId/:token",
    component: <UserResetPassword />,
  },
  { path: "/company-forgot-password", component: <ComapanyForgetPwd /> },
]

export { authProtectedRoutes, publicRoutes }
