import React from "react";
import { Col, Card, CardBody, Container } from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import Images
import img1 from "../../assets/images/companies/img-1.png";
import img2 from "../../assets/images/companies/img-2.png";
import img3 from "../../assets/images/companies/img-3.png";
import { withTranslation } from "react-i18next";
const Alert = props => {
  return (
    <React.Fragment>
        <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("All")}
            breadcrumbItem={props.t("Alerts")}
          />
      <Col xl="6">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Alerts</h4>
            <SimpleBar style={{ maxHeight: "390px" }}>
              <ul className="list-group">
                <li className="list-group-item border-0">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-xs">
                      <span className="avatar-title rounded-circle bg-warning">
                        {/* <img src={img3} alt="" height="18" /> */}
                        <i className="mdi mdi-alert-circle bg-warning font-size-18"></i>
                      </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">Recharging br2 in Device 2</h5>
                      <p className="text-muted mb-0">12/11/2022 12:30:33 IST</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item border-0">
                  <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-xs">
                    <span className="avatar-title rounded-circle bg-warning">
                        {/* <img src={img3} alt="" height="18" /> */}
                        <i className="mdi mdi-alert-circle bg-warning font-size-18"></i>
                      </span>
                    </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">
                      Recharging br1 in Device 1
                      </h5>
                      <p className="text-muted mb-0">12/11/2022 12:30:33 IST</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item border-0">
                  <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle bg-warning">
                        {/* <img src={img3} alt="" height="18" /> */}
                        <i className="mdi mdi-alert-circle bg-warning font-size-18"></i>
                      </span>
                    </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">
                      Recharging br1 in Device 1
                      </h5>
                      <p className="text-muted mb-0">12/11/2022 12:30:33 IST</p>
                    </div>
                  </div>
                </li>
                <li className="list-group-item border-0">
                  <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-xs">
                    <span className="avatar-title rounded-circle bg-warning">
                        {/* <img src={img3} alt="" height="18" /> */}
                        <i className="mdi mdi-alert-circle bg-warning font-size-18"></i>
                      </span>
                    </div>
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-14">
                      Recharging br1 in Device 1
                      </h5>
                      <p className="text-muted mb-0">12/11/2022 12:30:33 IST</p>
                    </div>
                  </div>
                </li>
              </ul>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Alert);
