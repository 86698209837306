import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"

import "chartist/dist/scss/chartist.scss"
import "../../assets/scss/chartist.scss"
import Spinearea from "pages/AllCharts/apex/SplineArea"
import Spinearea1 from "pages/AllCharts/apex/SplineArea1"

const ChartistChart = ({ deviceId, activeBreather, plotDates }) => {
  //meta title
  document.title = document.title = "Yogya | Devices"




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <Breadcrumbs title="Charts" breadcrumbItem="Chartist Charts" /> */}

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody >
                  <CardTitle className="mb-4">
                    Breather 1 & Breather 2 Live chart
                  </CardTitle>

                  <Spinearea1
                    deviceId={deviceId}
                    plotDates={plotDates}
                    dataColors='["--bs-success", "--bs-primary"]'
                    activeBreather={activeBreather}
                    value1={`Breather 1 Life`}
                    value2={`Breather 2 Life`}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody >
                  <CardTitle className="mb-4">
                    System Temperature & RH Live chart
                  </CardTitle>

                  <Spinearea
                    deviceId={deviceId}
                    plotDates={plotDates}
                    dataColors='["--bs-success", "--bs-primary"]'
                    activeBreather={"BR_1"}
                    value1={`System Temperature`}
                    value2={`System RH`}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Breather 02 Temperature & RH Live chart (Dual Axis Graph)
                  </CardTitle>

                  <Spinearea
                    deviceId={deviceId}
                    plotDates={plotDates}
                    dataColors='["--bs-success", "--bs-primary"]'
                    activeBreather={"BR_2"}
                    value1={`System Temperature`}
                    value2={`System RH`}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          {/* <Row>
            <Card>
              <CardBody >
                <CardTitle className="mb-4">
                  System Temperature & RH Live chart
                </CardTitle>

                <Spinearea
                  deviceId={deviceId}
                  plotDates={plotDates}
                  dataColors='["--bs-success", "--bs-primary"]'
                  activeBreather={"BR_1"}
                  value1={`System Temperature`}
                  value2={`System RH`}
                />
              </CardBody>
            </Card>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChartistChart
